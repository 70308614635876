<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mb-2"
      @click="gotoAddPartner()"
    >
      Add Partner
    </b-button>
    <b-row>
      <b-col col="12" v-if="active_role == 'corporate'">
        <partner-corporate></partner-corporate>
      </b-col>
      <b-col col="12" v-else-if="active_role == 'superadmin'">
        <partner-superadmin></partner-superadmin>
      </b-col>
    </b-row>
  </div>
</template>

<script>
	import {
		BRow, BCol,
    BCard, BCardBody, BButton,
	} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  import PartnerCorporate from './table/PartnerCorporate.vue'
  import PartnerSuperadmin from './table/PartnerSuperadmin.vue'
  
  export default {
    components: {
			BRow, BCol, 
      BCard, BCardBody, BButton,
      PartnerCorporate, PartnerSuperadmin
		},
    directives: {
      Ripple,
    },
    data() {
      return {
        active_role: null
      }
    },
		created() {
      this.active_role = localStorage.getItem('role')
      this.checkAuth()
    },
    methods: {
      gotoAddPartner() {
        this.$router.push({ path: '/partner/add' })
      }
    }
  }
</script>