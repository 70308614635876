<template>
  <div>
    <b-overlay
      :show="overlayUserMember"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card
        title="Data Member"
        no-body
        :aria-hidden="overlayUserMember ? true : null"
      >
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    ASC
                  </option>
                  <option :value="true">
                    DESC
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(name)="data">
            <div class="d-flex flex-row">
              <b-avatar
                v-if="data.item.image !== null"
                class="mr-50"
                :src="`${data.item.image}`"
              />
              <b-avatar
                v-else
                class="mr-50"
                variant="primary"
                :text="data.item.name | initial"
              />
              <div class="d-flex flex-column">
                <p class="m-0">
                  {{ data.item.name }}
                </p>
                <small class="text-muted">
                  <feather-icon icon="ActivityIcon" /> {{ data.item.date }}
                </small>
              </div>
            </div>
          </template>
          <template #cell(expired)="data">
            <b-badge
              v-if="!data.item.expired"
              variant="light-success"
            >
              Active
            </b-badge>
            <b-badge
              v-else
              variant="light-danger"
            >
              Expired
            </b-badge>
          </template>
          <template #cell(corporate_id)="data">
            {{ data.item.corporate }}
          </template>

          <template #cell(actions)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="SettingsIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="gotoEdit(data.item.id)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                    style="margin-top: -5px"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="gotoDelete(data.item.id)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                    style="margin-top: -5px"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay, BCard, BCardBody,
  BTable, BAvatar, BBadge,
  BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BCard,
    BCardBody,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  filters: {
    initial(val) {
      let result = ''
      const tokens = val.split('')

      if (tokens.length == 1) {
        result = tokens[0].substring(0, 1).toUpperCase()
      } else {
        for (let i = 0; i < 2; i++) {
          result += tokens[i].substring(0, 1).toUpperCase()
        }
      }

      return result
    },
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'cabang', label: 'Cabang', sortable: true },
        { key: 'corporate_id', label: 'Corporate', sortable: true },
        { key: 'expired', label: 'Status' },
        { key: 'actions', label: 'Actions' },
      ],
      overlayUserMember: true,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    items() {
      return this.$store.state.partner.partner
    },
  },
  mounted() {
    this.getDataPartner()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getDataPartner() {
      this.$store.dispatch('partner/loadPartner')
        .then(res => {
          this.overlayUserMember = false
        })
        .catch(error => {})
    },

    gotoEdit(id) {
      this.$router.push({ path: `/partner/edit/${id}` })
    },
    gotoDelete(id) {
      const cid = localStorage.getItem('client_id')

      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this data?', {
          title: 'Delete confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const config = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }

            this.$axios.delete(`${cid}/partner/${id}`, config)
              .then(res => {
                // Success code
                this.$bvToast.toast('Success', {
                  title: 'Delete partner',
                  variant: 'success',
                  solid: true,
                  toaster: 'b-toaster-bottom-right',
                })

                this.items = []
                this.getDataPartner()
              })
          }
        })
    },
  },
}
</script>
